import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';


const HeroCta = ({ cta, message, actionText, url }) => {
    return (
        <>

            <section className="container mx-auto text-center py-1 mb-2">
                <h1 className="w-full my-2 text-2xl sm:text-3xl font-bold leading-tight text-center text-white">{cta}</h1>
                <div className="w-full mb-4">
                    <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t" />
                </div>
                <h3 className="mt-4 text-lg sm:text-xl leading-tight">{message}</h3>
                <Link to={url}>
                    <button className="mx-auto lg:mx-0 hover:underline bg-white text-sm text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg">{actionText}</button>
                </Link>
            </section>
        </>
    );
}

HeroCta.propTypes = {
    cta: PropTypes.string,
    message: PropTypes.string,
    actionText: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
};

export default HeroCta;