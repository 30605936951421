import PropTypes from 'prop-types';
import React from 'react';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const Brands = ({ logos, title, description, bgColorTailwind, quantityItems, autoplay=false }) => {

  return (
    <>

      <div className={`mx-auto p-5 lg:pb-5 ${bgColorTailwind}`}>
        <h2 className="text-gray-800 text-center text-2xl md:text-3xl font-bold pb-5">{title}</h2>

        {description && <p className="text-gray-600 text-center text-xl md:text-1xl font-normal">{description}</p>}

        {/* Carousel Tabs */}
        
        <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay={autoplay}
        autoPlaySpeed={2000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        customTransition="all 2s linear"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass="flex justify-center items-center content-center opacity-100 hover:opacity-100 focus:opacity-100"
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 110
            },
            items: quantityItems,
            partialVisibilityGutter: 40
          },
          mobile: {
            breakpoint: {
              max: 850,
              min: 0
            },
            items: 3,
            partialVisibilityGutter: 30
          },
          tablet: {
            breakpoint: {
              max: 1110,
              min: 850
            },
            items: 4,
            partialVisibilityGutter: 30
          }
        }}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >

        {logos.map((logo, i) => (
            <div key={i} className="px-2 opacity-50 hover:opacity-100 focus:opacity-100 md:py-0">
              <img className={`${logo.classesBrand} estilosCarousel`} src={logo.img} alt="" />
            </div>
          ))}


      </Carousel>

      </div>
    </>
  );
}

Brands.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  logos: PropTypes.array.isRequired,
  bgColorTailwind: PropTypes.string.isRequired,
  quantityItems: PropTypes.number.isRequired,
  autoplay: PropTypes.bool
}

export default Brands;