import React from 'react';
import PropTypes from 'prop-types';

const SectionTitle = ({ title, classTitle}) => {
    return (
        <>
            <h1 className={`w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800 ${classTitle}`}>{title}</h1>
            <div className="w-full mb-4">
                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
            </div>

        </>
    );
}

SectionTitle.propTypes = {
    title: PropTypes.string.isRequired,
    classTitle: PropTypes.string,
}

export default SectionTitle;