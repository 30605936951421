import PropTypes from "prop-types";

import React from 'react';

const ContainerSection = ({ type, children }) => {

    let classes = {};

    switch (type) {
        case 1:
            classes.section = 'bg-white'
            classes.div = 'max-w-5xl mt-8'
            break;

        case 2:
            classes.section = 'bg-white pt-20'
            classes.div = 'flex flex-wrap pb-5'
            break;

        case 3:
            classes.section = 'bg-gray-100'
            classes.div = 'px-2 pt-4 pb-12 text-gray-800'
            break;

        case 4:
            classes.section = 'bg-white'
            classes.div = 'px-2 pt-4 pb-12 text-gray-800'
            break;

        case 5:
            classes.section = 'bg-white'
            classes.div = 'px-2 pt-4 pb-12 text-gray-800'
            break;

        case 6:
            classes.section = 'bg-white'
            classes.div = 'flex flex-wrap justify-center'
            break;

        default:
            break;
    }

    return (
        <>
            <section className={`py-1 ${classes.section}`}>

                <div className={`container mx-auto ${classes.div} `}>

                    {children}

                </div>

            </section>
        </>
    );
}

ContainerSection.propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.number
};

export default ContainerSection;
