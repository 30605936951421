import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';



const Hero = ({ image, title, subtitle, header, cta, ctaUrl }) => {
    return (
        <>
            <div className="pt-2 pb-10 ">
                <div className="container mt-5 px-3 mx-auto flex flex-col md:flex-row items-center justify-center">
                    {/*Left Col*/}
                    <div className={`flex flex-col w-full md:w-3/6 justify-center text-center ${image ? 'md:pl-10 md:text-left md:items-start': 'mb-10 md:py-20'}`}>
                        {header ? (<p className="uppercase tracking-loose w-full">{header}</p>) : ''}
                        {title ? (<h1 className="uppercase my-4 text-4xl lg:text-5xl font-bold leading-tight">{title}</h1>) : ''}
                        {subtitle ? (<p className="leading-normal text-2xl mb-8 w-full">{subtitle}</p>) : ''}
                        {cta ? (
                            <Link
                                className="mx-auto md:mx-0 hover:underline bg-white text-gray-800 rounded-full my-6 py-4 px-8 shadow-lg"
                                to={ctaUrl}>
                                <button className='font-bold uppercase'>{cta}</button>
                            </Link>
                        ) : ''}
                    </div>
                    {/*Right Col*/}
                    {image && (
                        <div className="w-full lg:w-5/6 pb-4 md:pb-0 flex justify-center">
                            <img className="w-6/6 lg:w-5/6 px-5" src={image} />
                        </div>

                    )}
                </div>
            </div>
        </>
    );
}

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    header: PropTypes.string,
    image: PropTypes.string,
    cta: PropTypes.string,
    ctaUrl: PropTypes.string,
}


export default Hero;